/** @format */

// From https://blog.logrocket.com/use-state-url-persist-state-usesearchparams/

import { useSearchParams } from "react-router-dom-v5-compat";
import { useSessionStorage } from "./useSessionStorage";
import { useLocalStorage } from "./useLocalStorage";

export type StateStickyOptions = {
  storeInSessionStorage?: boolean;
  browserStorageLongName?: string;
  storeInLocalStorage?: boolean;
  // TODO - more options to load from session/local? Just to turn off if a page wants to always start at a given default
  loadFromSessionStorage?: boolean;
  loadFromLocalStorage?: boolean;
};
const StateStickyOptionsDefaults: StateStickyOptions = {
  storeInSessionStorage: true,
  storeInLocalStorage: true,
  loadFromSessionStorage: true,
  loadFromLocalStorage: true,
};
export function useSearchParamsStateSticky(
  searchParamName: string,
  defaultValue: string,
  options?: StateStickyOptions,
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState: string) => void,
] {
  const calculatedOptions: StateStickyOptions = Object.assign(
    {},
    StateStickyOptionsDefaults,
    options ?? {},
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [sessionStored, setSessionStored] = useSessionStorage(
    calculatedOptions?.browserStorageLongName ??
      `com.welltower.operatorportal.${searchParamName}`,
    null,
  );
  const [localStored, setLocalStored] = useLocalStorage(
    calculatedOptions?.browserStorageLongName ??
      `com.welltower.operatorportal.${searchParamName}`,
    null,
  );

  const acquiredSearchParam = searchParams.get(searchParamName);
  let chosenState: string;
  if (acquiredSearchParam) {
    chosenState = acquiredSearchParam;
    // got from params, push down to storage
    // we probably want this initially if we start on a URL with params, make that sticky
    // but this is probably unnecessary every time we get from params after that
    if (
      calculatedOptions?.storeInSessionStorage &&
      sessionStored !== chosenState
    )
      setSessionStored(chosenState);
    if (calculatedOptions?.storeInLocalStorage && sessionStored !== chosenState)
      setLocalStored(chosenState);
  } else if (calculatedOptions?.loadFromSessionStorage && sessionStored) {
    chosenState = sessionStored;
    // got from session, push down to localStorage
    if (calculatedOptions?.storeInLocalStorage && sessionStored !== chosenState)
      setLocalStored(chosenState);
    // TODO - should we auto-set in param here? I think probably not, so initial state is unstored and we just return defaultValue back?
  } else if (calculatedOptions?.loadFromLocalStorage && localStored) {
    chosenState = localStored;
    // TODO - should we auto-set in param here? I think probably not, so initial state is unstored and we just return defaultValue back?
  } else {
    chosenState = defaultValue;
  }
  const searchParamsState = chosenState;

  const setSearchParamsState = (newState: string) => {
    if (newState === acquiredSearchParam) {
      // don't set if these are the same? Because it looks like setting this state often pushes the same URL on history? Possibly related to https://github.com/remix-run/react-router/issues/11624 ; and/or that this is exposing a URLSearchParams object?
      //console.debug(
      //  `NOT setting searchParam ${searchParamName} to ${newState} (prev searchParam was ${acquiredSearchParam}, best guess was ${chosenState})`,
      //);
      return;
    }
    // TODO - if newState === chosenState, can we set that param as a replace instead of a push?
    console.debug(
      `setting searchParam ${searchParamName} to ${newState} (prev searchParam was ${acquiredSearchParam}, best guess was ${chosenState})`,
    );
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce(
        (o, [key, value]) => ({ ...o, [key]: value }),
        {},
      ),
      { [searchParamName]: newState },
    );
    setSearchParams(next);
    if (calculatedOptions?.storeInSessionStorage) {
      console.log(`storing in sessionStorage ${newState}`);
      setSessionStored(newState);
    }
    if (calculatedOptions?.storeInLocalStorage) {
      console.log(`storing in localStorage ${newState}`);
      setLocalStored(newState);
    }
  };
  return [searchParamsState, setSearchParamsState];
}
