/** @format */
import React from "react";
import { buSettingsUrl } from "../../util/ApiUrlUtils";
import { Col, Container, Row, Table } from "reactstrap";
import _ from "lodash";
import WelltowerBuSettingEditFormButton from "../../components/welltower-bu-setting-edit-form/WelltowerBuSettingEditFormButton";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { LoadingSpinnerBig } from "../../components/LoadingSpinners";
import { ContextOperatorPicker } from "../../components/filters/ContextOperatorPicker";

const WelltowerBuSettingsPage = () => {
  const { operatorId } = useParams();
  const navigate = useNavigate();

  const {
    data: buSettings,
    isLoading,
    // TODO isError,
    refetch,
  } = useOktaQuery(
    // @ts-ignore
    buSettingsUrl(operatorId),
  );

  return (
    <Container className="wtop-accountant-operators">
      <Row className="pb-2">
        <Col sm={"4"}>
          <ContextOperatorPicker
            selectedId={
              operatorId ? parseInt(operatorId) : "NOT_SELECTED" // This lets us send the URL's operatorId into the picker to set it and make it sticky.
            }
            onSelectionChange={(operatorId) => {
              if (typeof operatorId === "number")
                navigate(`/welltower-bu-settings/${operatorId}`);
            }}
            requireChoice={true}
            showAllChoice={false}
          />
        </Col>
      </Row>
      <Row className="header-row">
        <h4>Operator BU Settings</h4>
      </Row>
      <Row>
        <Col>
          {isLoading && <LoadingSpinnerBig />}
          {buSettings && (
            <Table>
              <thead>
                <tr>
                  <th>Yardi Property Code</th>
                  <th>Name</th>
                  <th>Vantage Code</th>
                  <th>Adjust Census To Vantage Capacity</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.sortBy(buSettings, ["source", "buCode"]).map((buSetting) => (
                  <tr key={buSetting.buCode}>
                    <td>{buSetting.buCode}</td>
                    <td>{buSetting.buName}</td>
                    <td
                      className={
                        buSetting.vantageCode === "NPXX"
                          ? "font-weight-light text-muted"
                          : ""
                      }
                    >
                      {buSetting.vantageCode}
                    </td>
                    <td>
                      {buSetting.adjustCensusToVantageCapacity ? "Yes" : ""}
                    </td>
                    <td>
                      {!!buSetting.vantageCode &&
                        buSetting.vantageCode !== "NPXX" && (
                          <WelltowerBuSettingEditFormButton
                            buSetting={buSetting}
                            onSave={() => refetch()} // TODO - or have form invalidate for us?
                          />
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default WelltowerBuSettingsPage;
