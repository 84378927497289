/** @format */

import React from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  ACCEPTABLE_PROCESSED_FILE_TYPES,
  DROPZONE_MAX_FILE_SIZE_MB,
  DROPZONE_MAX_PROCESSED_FILE_SIZE_MB,
} from "../../util/constants";
import { welltowerName } from "../../util/NameUtils";
import BaseRddCard, {
  LoadingIndicatorRow,
  nonEmptyFileValidator,
} from "../rdd-card/BaseRddCard";
import RddCardAlert from "../rdd-card/RddCardAlert";
import RddCardHeader from "../rdd-card/RddCardHeader";
import RddStatusIcon from "../rdd-card/RddStatusIcon";
import RddStatusMessage from "../rdd-card/RddStatusMessage";
import AccountantUnmappedItemSummary from "./AccountantUnmappedItemSummary";
import RddCallToAction from "../rdd-card/RddCallToAction";
import SubmittedFile from "../../types/SubmittedFile";
import AccountantSubmittedFile from "./AccountantSubmittedFile";
import RddUploadHistory from "../rdd-card/RddUploadHistory";
import {
  hasAwaitingETLStatus,
  hasReadyForETLStatus,
  isSendToRPAEnabled,
  welltowerStatusMessage,
} from "../../util/StatusUtils";
import RddLockStatus from "./RddLockStatus";
import {
  canApproveRPA,
  canEditDueDates,
  canRecordWelltowerAccountingLoad,
  canUploadFiles,
} from "../../util/OktaUtils";
import SendToRPAButton from "./SendToRPAButton";
import RequirementDueDateGlDateEditFormButton from "../RequirementDueDateGlDateEditForm";
import RequirementDueDate from "../../types/RequirementDueDate";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";
import WelltowerAccountingLoadEditButton from "./WelltowerAccountingLoadEditButton";
import { formatDateMonthYear } from "../../util/DateUtils";

const showGLDate = (rdd: RequirementDueDate) => {
  const hasNonMEGLDate = rdd.glDate && rdd.glDate !== rdd.periodEndDate;
  const hasNonMEGLSetting =
    rdd.requirement.glDateSetting &&
    rdd.requirement.glDateSetting.name !== "MONTH_END";
  return hasNonMEGLDate || hasNonMEGLSetting;
};

class AccountantRddCard extends BaseRddCard {
  render() {
    const { authState, requirementDueDate } = this.props;
    const {
      latestFile,
      unmappedAccounts,
      unmappedProperties,
      unmappedColumns,
      previousFiles,
    } = this.state;

    const canUpload =
      canUploadFiles(
        this.props.authState,
        requirementDueDate.requirement.operator,
        requirementDueDate.requirement,
      ) && !requirementDueDate.locked;

    const ETLStatus = latestFile &&
      hasAwaitingETLStatus(latestFile?.status) &&
      latestFile.welltowerAccountingLoads?.length > 0 && (
        <ul>
          {latestFile.welltowerAccountingLoads.map(
            (wal: WelltowerAccountingLoad) => (
              <li key={wal.id}>
                {wal.id} - file {wal.uploadFilename} - {wal.status}
                {canRecordWelltowerAccountingLoad(
                  this.props.authState,
                  requirementDueDate.requirement.operator,
                  requirementDueDate.requirement,
                ) && (
                  <WelltowerAccountingLoadEditButton
                    wal={wal}
                    file={latestFile}
                    buttonType={"link"}
                    documentType={
                      requirementDueDate.requirement
                        .documentType /* because latestFile often doesn't include rdd to avoid a cycle */
                    }
                    afterSave={(data: any) => {
                      this.loadFiles(requirementDueDate);
                    }}
                  />
                )}
              </li>
            ),
          )}
        </ul>
      );
    /*const jdeFlowButtons = hasReadyForJDEStatus(
      latestFile,
      requirementDueDate
    ) &&
      canDownloadWelltowerFiles(
        this.props.authState,
        requirementDueDate.requirement.operator
      ) &&
      hasReadyForJDEStatus(latestFile, requirementDueDate) &&
      canRecordJdeInfo(
        this.props.authState,
        requirementDueDate.requirement.operator,
        requirementDueDate.requirement
      ) &&
      latestFile &&
      latestFile.welltowerRPADownloadTokenURI && (
        <Row className={"mb-3"}>
          <Col>
            <Row className={"justify-content-center align-items-center"}>
              <Col sm="8" className={"text-center "}>
                <DownloadJDECSVButton file={latestFile} authState={authState} />
                <span className={"px-3"}>then</span>
                <JDEInfoButton
                  file={latestFile}
                  documentType={requirementDueDate.requirement.documentType}
                  authState={authState}
                  onSaveJDEInfo={(data: any) => {
                    this.loadFiles(requirementDueDate);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    */
    const allFileStatuses = requirementDueDate.requirement.portalProcessed
      ? undefined
      : previousFiles?.map((f) => welltowerStatusMessage(f));

    let welltowerStatus = welltowerStatusMessage(requirementDueDate);

    return (
      <div
        className={`rdd-card accountant-rdd-card`}
        data-testid={`accountant-rdd-card`}
      >
        <Dropzone
          accept={
            requirementDueDate.requirement.portalProcessed
              ? ACCEPTABLE_PROCESSED_FILE_TYPES
              : undefined
          }
          validator={nonEmptyFileValidator}
          noClick={true}
          noKeyboard={true}
          minSize={1}
          maxSize={
            (requirementDueDate.requirement.portalProcessed
              ? DROPZONE_MAX_PROCESSED_FILE_SIZE_MB
              : DROPZONE_MAX_FILE_SIZE_MB) *
            1024 *
            1024
          }
          onDrop={(accepted: File[], fileRejections: FileRejection[]) =>
            this.onDrop(accepted, fileRejections)
          }
          multiple={!requirementDueDate.requirement.portalProcessed}
          disabled={!canUpload}
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => {
            return (
              <div {...getRootProps()}>
                <Card
                  className={`mt-5 ${isDragActive ? "dropzone--isActive" : ""}`}
                >
                  <RddCardAlert
                    error={this.state.error}
                    success={this.state.success}
                    warning={this.state.warning}
                    toggleAlertVisibility={() => {
                      this.setState(
                        {
                          error: null,
                          success: null,
                          warning: null,
                        },
                        () => this.loadFiles(this.props.requirementDueDate),
                      );
                    }}
                  />
                  <CardBody className={"pb-0"}>
                    <input {...getInputProps()} />
                    {this.state.block && (
                      <LoadingIndicatorRow
                        onClose={() =>
                          this.setState({
                            block: false,
                            cancelFilesStatusLoop: true,
                          })
                        }
                        message={this.state.blockMessage}
                      />
                    )}
                    <Row>
                      <Col md={"1"}>
                        <RddStatusIcon
                          statusMessage={welltowerStatus}
                          welltower={true}
                          allFileStatuses={allFileStatuses}
                        />
                      </Col>
                      <Col>
                        <RddCardHeader
                          rdd={requirementDueDate}
                          documentType={
                            requirementDueDate.requirement.documentType
                          }
                          rddName={welltowerName(
                            requirementDueDate.requirement,
                          )}
                          rddDueDate={requirementDueDate.dueDate}
                        />
                        {welltowerStatus.id !== "LOADED_WELLTOWER" &&
                          showGLDate(requirementDueDate) && (
                            <div
                              style={{ marginTop: "-1rem" }}
                              className={"ml-2 mb-5 text-muted"}
                            >
                              Posting to Welltower on
                              <RequirementDueDateGlDateEditFormButton
                                requirementDueDate={requirementDueDate}
                                canEdit={canEditDueDates(
                                  this.props.authState,
                                  requirementDueDate.requirement.operator,
                                  requirementDueDate.requirement,
                                )}
                                submitCallback={
                                  this.props.receiveUpdatedRequirementDueDate
                                }
                              />
                            </div>
                          )}
                      </Col>
                      <Col md={"1"} className={"text-right"}>
                        <RddLockStatus
                          rdd={requirementDueDate}
                          callback={this.props.receiveUpdatedRequirementDueDate}
                          onError={(error: any) =>
                            this.setState({
                              error,
                              success: null,
                              warning: null,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className={"mb-3"}>
                      <Col className={"text-center"}>
                        <RddStatusMessage
                          uploadingFilename={this.state.uploadingFilename}
                          uploadingBatchProgress={
                            this.state.uploadingBatchProgress
                          }
                          uploadingFilePercent={this.state.uploadingFilePercent}
                          statusMessage={welltowerStatus}
                          logEntries={latestFile ? latestFile.logEntries : []}
                          downloadFullLogEntry={() =>
                            this.downloadFileFullLogEntry(latestFile)
                          }
                          allFileStatuses={allFileStatuses}
                        />
                      </Col>
                    </Row>
                    <AccountantUnmappedItemSummary
                      operatorId={requirementDueDate.requirement.operator.id}
                      unmappedAccounts={unmappedAccounts || []}
                      unmappedProperties={unmappedProperties || []}
                      unmappedColumns={unmappedColumns || []}
                      requirement={requirementDueDate.requirement}
                      backLink={
                        this.props.location.pathname +
                        (this.props.location.search ?? "")
                      }
                      backLinkName={`${requirementDueDate.requirement.operator.name} ${formatDateMonthYear(
                        requirementDueDate.periodEndDate,
                      )}`}
                    />
                    {hasReadyForETLStatus(latestFile, requirementDueDate) &&
                      isSendToRPAEnabled(requirementDueDate) &&
                      canApproveRPA(
                        this.props.authState,
                        requirementDueDate.requirement.operator,
                        requirementDueDate.requirement,
                      ) && (
                        <>
                          <SendToRPAButton
                            file={latestFile}
                            authState={authState}
                            sendToRPA={this.sendToRPA}
                          />
                          {/*" "}
                          {<Row className={"mb-3"}>
                            <Col>
                              <Row>
                                <Col
                                  sm="4"
                                  className={"text-center offset-sm-4"}
                                >
                                  or
                                </Col>
                              </Row>
                            </Col>
                          </Row>*/}
                        </>
                      )}
                    {ETLStatus}
                    {/*
                    {jdeFlowButtons}
                    {!jdeFlowButtons &&
                      hasReadyForJDEStatus(latestFile, requirementDueDate) &&
                      canDownloadWelltowerFiles(
                        this.props.authState,
                        requirementDueDate.requirement.operator
                      ) &&
                      latestFile &&
                      latestFile.welltowerRPADownloadTokenURI && (
                        <Row className={"mb-3"}>
                          <Col>
                            <Row>
                              <Col sm="4" className={"text-center offset-sm-4"}>
                                <DownloadJDECSVButton
                                  file={latestFile}
                                  authState={authState}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}

                    {!jdeFlowButtons &&
                      hasCanRecordLoadStatus(latestFile, requirementDueDate) &&
                      canRecordJdeInfo(
                        this.props.authState,
                        requirementDueDate.requirement.operator,
                        requirementDueDate.requirement
                      ) && (
                        <Row className={"mb-3"}>
                          <Col>
                            <Row>
                              <Col sm="4" className={"text-center offset-sm-4"}>
                                <JDEInfoButton
                                  file={latestFile}
                                  documentType={
                                    requirementDueDate.requirement.documentType
                                  }
                                  authState={authState}
                                  onSaveJDEInfo={(data: any) => {
                                    this.loadFiles(requirementDueDate);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      */}
                    <RddCallToAction
                      requirementDueDate={requirementDueDate}
                      locked={requirementDueDate.locked}
                      previousUploadStatus={requirementDueDate.status}
                      onOpenFileBrowser={open}
                      retractFile={this.retract}
                      undoRPA={this.undoRPA}
                      latestFile={this.state.latestFile}
                      operator={requirementDueDate.requirement.operator}
                      authState={authState}
                    />
                  </CardBody>
                  <RddUploadHistory
                    files={previousFiles}
                    locked={requirementDueDate.locked}
                    downloadAllFiles={
                      !requirementDueDate.requirement.documentType
                        .process /* TODO - replace with canUploadMultiple flag? */ &&
                      this.downloadAllFiles
                    }
                  >
                    {previousFiles.map((file: SubmittedFile) => {
                      return (
                        <AccountantSubmittedFile
                          file={file}
                          authState={authState}
                          locked={requirementDueDate.locked}
                          downloadFile={this.downloadFile}
                          retractFile={this.retract}
                          reuploadFile={this.reupload}
                          resumeFile={this.resume}
                          failFile={this.fail}
                          sendToRPA={this.sendToRPA}
                          deleteFile={this.delete}
                          downloadFullLogEntry={this.downloadFileFullLogEntry}
                          onSaveJDEInfo={(data: any) => {
                            this.loadFiles(data || requirementDueDate);
                          }}
                          key={file.id}
                          preDownloadCallback={this.block}
                          postDownloadCallback={this.unblock}
                        />
                      );
                    })}
                  </RddUploadHistory>
                </Card>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default AccountantRddCard;
