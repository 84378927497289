/** @format */

import React from "react";
import { withOktaAuth } from "@okta/okta-react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import _ from "lodash";
import Operator from "../types/Operator";
import {
  canEditDueDates,
  canEditRequirements,
  canEditRequirementsAnyDoc,
  isWelltower,
} from "../util/OktaUtils";
import Requirement from "../types/Requirement";
import RequirementCard from "../components/RequirementCard";
import RequirementEditFormButton from "../components/RequirementEditForm";
import { alertDuplicateIds } from "../util/CollectionUtils";
import { deleteWithToken, fetchToState } from "../util/FetchUtils";
import { operatorsUrl } from "../util/ApiUrlUtils";
import { downloadUrl } from "../util/OneTimeDownloader";
import { ContextOperatorPicker } from "../components/filters/ContextOperatorPicker";
import {
  withDocumentTypeContext,
  withOperatorContext,
} from "../components/filters/PickerContext";
import { ContextDocumentTypePicker } from "../components/filters/ContextDocumentTypePicker";
import { LoadingSpinnerBig } from "../components/LoadingSpinners";

const requirementsEndpoint = "/api/requirements/all"; // TODO - change to load /api/operators/{operatorId}/requirements when operator changes?

interface RequirementDashboardState {
  requirements: Requirement[];
  operators: Operator[];
  error?: string;
}

// TODO - if we come up with a good way to modify hook-fetched data (we update our state from edits in the cards),
//        then we can switch to FC here and hooks for json and okta
const RequirementDashboard = withOktaAuth(
  class RequirementDashboard extends React.Component<
    any,
    RequirementDashboardState
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        //filterOperatorId: "NOT_SELECTED",
        requirements: [],
        operators: [],
      };
      //this.setFilterOperatorId = this.setFilterOperatorId.bind(this);
      this.receiveUpdatedRequirement =
        this.receiveUpdatedRequirement.bind(this);
      this.deleteRequirement = this.deleteRequirement.bind(this);
    }

    componentDidMount() {
      this.loadJson();
    }

    async loadJson() {
      fetchToState(this, "requirements", requirementsEndpoint);
      fetchToState(this, "operators", operatorsUrl());
    }

    async deleteRequirement(requirement: Requirement) {
      if (!requirement.id) return;

      const url = `/api/operators/${requirement.operator.id}/requirements/${requirement.id}`;
      return deleteWithToken(url, this.props.authState).then((response) => {
        this.setState((prevState) => ({
          requirements: prevState.requirements.filter(
            (r2) => r2.id !== requirement.id,
          ),
        }));
        return response;
      });
    }

    receiveUpdatedRequirement(r: Requirement) {
      if (!r) return;
      // If our save endpoint returns the full Requirement object in the shape we want, replace the one in state with the updated copy
      this.setState((prevState) => ({
        requirements: [r].concat(
          prevState.requirements.filter((r2) => r2.id !== r.id),
        ),
      }));
    }

    filterRequirementsByOperator(requirements: Array<Requirement>) {
      let filteredReqs = requirements;

      // TODO - probably should update this to fetch by operator, then filter by documentType
      //        unless there's a demand to see one type across all operators, but if Rose hasn't asked yet, maybe that's not useful?

      if (
        this.props.selectedOperatorId &&
        this.props.selectedOperatorId !== "FAVORITES"
      ) {
        filteredReqs = filteredReqs.filter((req: Requirement) => {
          return req.operator.id === this.props.selectedOperatorId;
        });
      }

      // If operator type favorites are selected, filter requirements by favorite operators
      //if (
      //  this.props.selectedOperatorId &&
      //  this.props.selectedOperatorId === "FAVORITES"
      //) {
      //  filteredReqs = filteredReqs.filter((req: Requirement) => {
      //    return (
      //      _.find(this.state.filterOperatorFavorites, (fav: any) => {
      //        return fav.operator.id === req.operator.id;
      //      }) !== undefined
      //    );
      //  });
      //}

      return filteredReqs;
    }

    filterRequirementsByDocumentType(requirements: Array<Requirement>) {
      let filteredReqs = requirements;

      filteredReqs = filteredReqs.filter((req: Requirement) =>
        this.props.isDocumentTypeSelected(req.documentType),
      );

      return filteredReqs;
    }

    render() {
      let filteredReqs = this.filterRequirementsByDocumentType(
        this.filterRequirementsByOperator(this.state.requirements),
      );

      const sortNameField = isWelltower(this.props.authState)
        ? "name"
        : "externalName";
      // Sort filtered requirements
      filteredReqs = _.orderBy(
        filteredReqs,
        ["endDate", "operator.name", "documentType.name", sortNameField],
        ["desc", "asc", "asc", "asc"],
      );

      alertDuplicateIds(this.state.requirements); // TODO - remove once we're sure we figured out the weird duplicates.

      const canEdit = canEditRequirementsAnyDoc(
        this.props.authState,
        this.state.operators[0],
      );

      return (
        <Container>
          <Row>
            <Col sm="4" />
            <Col>
              <h3>Requirements</h3>
            </Col>
          </Row>
          <Row style={{ padding: "0.75rem" }}>
            <Col sm={"4"}>
              <ContextOperatorPicker requireChoice />
            </Col>
            <Col sm={"3"}>
              <ContextDocumentTypePicker showAllChoice />
            </Col>
            <Col sm />
            <Col sm={"auto"} className={"text-end"}>
              <Button
                color={"link"}
                onClick={() => {
                  let link = `/api/requirement/downloadToken`;
                  if (typeof this.props.selectedOperatorId === "number") {
                    link += `?operator=${this.props.selectedOperatorId}`;
                    if (this.props.selectedDocumentTypeId) {
                      link += `&documentType=${this.props.selectedDocumentTypeId}`;
                    }
                  }
                  downloadUrl(this.props.authState, link);
                }}
                className={"wtop-btn"}
              >
                Export
              </Button>
            </Col>

            <Col sm={"auto"} className={"text-end"}>
              {canEdit && (
                <RequirementEditFormButton
                  disabled={
                    !this.props.selectedOperatorId ||
                    this.props.selectedOperatorId === "NOT_SELECTED" ||
                    this.props.selectedOperatorId === 0 ||
                    this.props.selectedOperatorId === "FAVORITES"
                  }
                  receiveUpdatedRequirement={this.receiveUpdatedRequirement}
                  operator={
                    this.props.selectedOperatorId &&
                    this.state.operators.find(
                      (it) => it.id === this.props.selectedOperatorId,
                    )
                  }
                  allOperators={this.state.operators}
                  canEdit={canEdit}
                />
              )}
            </Col>
          </Row>

          {(!this.state.requirements ||
            this.state.requirements.length === 0) && <LoadingSpinnerBig />}
          {this.state.requirements?.length > 0 &&
            (!filteredReqs || filteredReqs.length === 0) && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      No requirements match the selected criteria.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

          {filteredReqs &&
            filteredReqs.map((r: any) => (
              <RequirementCard
                key={r.id}
                requirement={r}
                receiveUpdatedRequirement={this.receiveUpdatedRequirement}
                deleteRequirement={this.deleteRequirement}
                canEdit={canEditRequirements(
                  this.props.authState,
                  this.state.operators[0],
                  r,
                )}
                canEditDueDates={canEditDueDates(
                  this.props.authState,
                  this.state.operators[0],
                  r,
                )}
              />
            ))}
        </Container>
      );
    }
  },
);

export default withOperatorContext(
  withDocumentTypeContext(RequirementDashboard),
);
