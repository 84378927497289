/** @format */
import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import SubmittedFile from "../../types/SubmittedFile";
import { downloadUrl } from "../../util/OneTimeDownloader";
import JDEInfo from "./JDEInfo";
import { describeDueDatePeriodEnd } from "../../util/NameUtils";
import { DeveloperModeOnly } from "../../components/DeveloperMode";
import useFetchJsonWithLoading from "../../hooks/useFetchJsonWithLoadingHook";
import _ from "lodash";
import RequirementDueDate from "../../types/RequirementDueDate";
import { recordsBatchNumber } from "../../util/StatusUtils";
import RPADashboardPicker from "./RPADashboardPicker";
import YardiETLStatusDto from "../../types/YardiETLStatusDto";
import { saveFileJDEBatch } from "../../api/SubmittedFileAPI";
import { useParams } from "react-router-dom";
import { formatDateISO } from "../../util/DateUtils";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import WelltowerAccountingLoad from "../../types/WelltowerAccountingLoad";

const AcceptedFileRow = ({
  file,
  yardiLoading,
  yardiETLStatuses,
  isDocType,
}: {
  file: SubmittedFile;
  yardiLoading: boolean;
  yardiETLStatuses: YardiETLStatusDto[];
  isDocType: boolean;
}) => {
  const { authState } = useOktaAuth();
  const [isLoadRecorded, setLoadRecorded] = useState(false);
  const url = file.welltowerRPADownloadTokenURI;
  const yardiStatus = !yardiLoading
    ? yardiETLStatuses.find((s: any) => s.submittedFileId === file.id)
    : undefined;

  const storeYardiStatusClick = async (
    dueDateId: number,
    status: YardiETLStatusDto,
  ) => {
    let payload = {
      uploadIdentifier: status.batchNumber,
      uploadTime: status.endTime || new Date(),
      uploadUserId: "Yardi Task Runner",
      //failed: !status.success,
      status: status.success ? "SUCCESS" : "FAILED",
    };
    await saveFileJDEBatch(
      payload as WelltowerAccountingLoad,
      dueDateId,
      status.submittedFileId!,
      authState,
    );
    setLoadRecorded(true);
  };

  return (
    <Card
      key={file.id}
      data-botid={"fileCard"}
      data-rpaid={`file-${file.id}-card`}
    >
      <DeveloperModeOnly>
        <CardHeader>
          {file.requirementDueDate.requirement.operator.name}{" "}
          {describeDueDatePeriodEnd(file.requirementDueDate)}{" "}
          {file.requirementDueDate.requirement.name}
          <br />
          {file.originalFilename}
        </CardHeader>
      </DeveloperModeOnly>
      <CardBody>
        <Row>
          <Col>
            <p data-botid={"fileId"}>{file.id}</p>
          </Col>
          <Col>
            <p data-botid={"requirementDueDateId"}>
              {file.requirementDueDate.id}
            </p>
          </Col>
          <Col>
            <p data-botid={"ledger"}></p>
          </Col>
          <Col>
            <Button
              color={"primary"}
              onClick={() => downloadUrl(authState, url)}
              className={"wtop-btn"}
              data-botid={"downloadButton"}
              data-rpaid={`file-${file.id}-download`}
            >
              Download
            </Button>
          </Col>

          {!isLoadRecorded && (
            <JDEInfo
              requirementDueDateId={file.requirementDueDate.id}
              documentType={file.requirementDueDate.requirement.documentType}
              fileId={file.id}
              authState={authState}
            />
          )}
        </Row>
        <Row>
          <Col sm={"auto"}>
            <p data-botid={"operatorName"}>
              {file.requirementDueDate.requirement.operator.name}
            </p>
          </Col>
          {!isDocType && (
            <Col sm={"auto"}>
              <p data-botid={"requirementId"}>
                {file.requirementDueDate.requirement.id}
              </p>
            </Col>
          )}
          <Col sm={"auto"}>
            <p data-botid={"requirementName"}>
              {file.requirementDueDate.requirement.name}
            </p>
          </Col>
          <Col sm={"auto"}>
            <p data-botid={"requirementPeriodEndDate"}>
              {formatDateISO(file.requirementDueDate.periodEndDate)}
            </p>
          </Col>
        </Row>
        {yardiStatus &&
          (yardiStatus.success ? (
            <Row>
              <Col sm={12}>
                {isLoadRecorded ? (
                  "Yardi load has been recorded."
                ) : (
                  <>
                    <Button
                      color={"success"}
                      onClick={() =>
                        storeYardiStatusClick(
                          file.requirementDueDate.id,
                          yardiStatus,
                        )
                      }
                    >
                      Record in Portal
                    </Button>{" "}
                    <strong>Yardi ETL result:</strong>{" "}
                    {yardiStatus.resultDescription}
                  </>
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={12}>
                {isLoadRecorded ? (
                  "Yardi failure has been recorded."
                ) : (
                  <>
                    <Button
                      color={"danger"}
                      onClick={() =>
                        storeYardiStatusClick(
                          file.requirementDueDate.id,
                          yardiStatus,
                        )
                      }
                    >
                      Record in Portal
                    </Button>{" "}
                    <strong>Yardi ETL failure:</strong>{" "}
                    {yardiStatus.resultDescription}
                  </>
                )}
              </Col>
            </Row>
          ))}
      </CardBody>
    </Card>
  );
};
const RPADashboard = () => {
  const { authState } = useOktaAuth();
  const { documentType }: any = useParams();
  const isYardiETL = !documentType || documentType === "ETL";
  const isPull = documentType === "PULL";
  const isDocType = documentType && !isYardiETL && !isPull;
  // TODO - I think we're only including ETL and PULL now, so any other type logic (and byDocumentType/byStatus API) can go
  const { data: requirementDueDates, loading } = useFetchJsonWithLoading(
    isYardiETL
      ? `/api/requirementDueDates/filesAwaitingETL`
      : isPull
        ? `/api/requirementDueDates/filesAwaitingPull`
        : `/api/requirementDueDates/byDocumentType/${documentType}/byStatus/AWAITING_RPA,AWAITING_ETL_RESULTS,AWAITING_PULL_RESULTS`,
    authState,
  );
  const {
    data: yardiETLStatuses,
    loading: yardiLoading,
  }: { data: YardiETLStatusDto[]; loading: boolean } = useFetchJsonWithLoading(
    isYardiETL ? `/api/etlLogs` : null,
    authState,
  );

  // TODO - fetch all WAITING WelltowerAccountingLoads and hook them up below

  if (loading) {
    return <LoadingSpinner />;
  }

  // Loop through remaining RDDs and fetch all submitted files
  let submittedFiles;
  if (isDocType) {
    // Old pre-Yardi logic (still used for Vantage and other non-financial documentTypes?)
    submittedFiles = requirementDueDates
      .map((rdd: RequirementDueDate) => {
        if (rdd.latestFile) {
          rdd.latestFile.requirementDueDate = rdd;
        }
        return rdd.latestFile;
      })
      .filter((f: SubmittedFile) => !!f);
  } else {
    // Yardi filesAwaitingETL returns a list of SubmittedFiles
    submittedFiles = requirementDueDates;
  }

  return (
    <Container className={"rpa-dashboard"}>
      <Row>
        <Col>
          <RPADashboardPicker />
        </Col>
      </Row>
      {!submittedFiles.length ? (
        <Row>
          <Col className={"text-center"}>
            <Card>
              <CardBody
                data-botid={"dashboardNoFiles"}
                data-rpaid={"dashboard-no-files"}
              >
                {isYardiETL
                  ? "No files awaiting ETL results"
                  : isPull
                    ? "No files awaiting Snowflake pull"
                    : `No ${documentType} files ready for RPA`}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <p>Submitted File ID</p>
            </Col>
            <Col>
              <p>Due Date ID</p>
            </Col>
            <Col>
              <p>Posting Book</p>
            </Col>
            <Col>
              <p>Download</p>
            </Col>
            <Col>
              {(isYardiETL || recordsBatchNumber({ id: documentType })) && (
                <p>Batch #</p>
              )}
            </Col>
            <Col>
              <p>Complete</p>
            </Col>
            <Col>
              <p>Fail</p>
            </Col>
          </Row>
          {_.sortBy(submittedFiles, "uploadTime").map((f) => (
            <AcceptedFileRow
              key={f.id}
              file={f}
              yardiLoading={yardiLoading}
              yardiETLStatuses={yardiETLStatuses}
              isDocType={isDocType}
            />
          ))}
        </>
      )}
    </Container>
  );
};

export default RPADashboard;
