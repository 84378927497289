/** @format */
import SubmittedFile from "../../types/SubmittedFile";
import { useOktaAuth } from "@okta/okta-react";
import FileStatusChangeLogDto from "../../types/FileStatusChangeLogDto";
import useFetchJsonWithLoading from "../../hooks/useFetchJsonWithLoadingHook";
import { DeveloperModeOnly } from "../DeveloperMode";
import _ from "lodash";
import { formatDateTime, formatDateTimeSeconds } from "../../util/DateUtils";
import WithModal from "../WithModal";
import { DropdownItem } from "reactstrap";
import React from "react";
import { statusTextColor } from "../../util/StatusUtils";
import { LoadingSpinner } from "../LoadingSpinners";

const StatusChangeLog = ({ file }: { file: SubmittedFile }) => {
  const { authState } = useOktaAuth();

  const {
    data,
    loading,
  }: { data: FileStatusChangeLogDto[]; loading: boolean } =
    useFetchJsonWithLoading(
      `/api/requirementDueDates/${file.requirementDueDate.id}/files/${file.id}/statusLog`,
      authState,
    );
  if (loading) return <LoadingSpinner />;
  return (
    <div>
      <div className={"font-weight-light px-2 align-baseline"}>
        <DeveloperModeOnly>
          <span className={"text-muted"}>
            {file.requirementDueDate.requirement.operator.id}.
            {file.requirementDueDate.requirement.id}.
            {file.requirementDueDate.id}.{file.id}{" "}
          </span>
        </DeveloperModeOnly>
        <span>{file.originalFilename}</span>
        <br />
        <small>
          Uploaded by{" "}
          {`${file.uploadUserDisplayName} on ${formatDateTime(
            file.uploadTime,
          )}`}
        </small>
      </div>
      <table className={"table table-sm"}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Time</th>
            <th>Who</th>
            <DeveloperModeOnly>
              <th>AWS Instance</th>
            </DeveloperModeOnly>
          </tr>
        </thead>
        <tbody>
          {_.sortBy(data, "changeTime").map((l) => (
            <tr key={l.id}>
              <td className={statusTextColor(l.status.color)}>
                {l.status.statusLong}
              </td>
              {/* TODO - translate this status when we move the translations like "ready for spot db" out of AccountantRddCard */}
              <td>{formatDateTimeSeconds(l.changeTime)}</td>
              <td>{l.user}</td>
              <DeveloperModeOnly>
                <td>{l.instanceName}</td>
              </DeveloperModeOnly>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FileStatusChangeLogButton = WithModal(
  ({ file, ...props }: { file: SubmittedFile } & Partial<any>) => (
    <>
      <DropdownItem title={"Audit Log"} onClick={props.showModal}>
        <small data-testid={"status-change-log-button"}>
          File Status audit log
        </small>
      </DropdownItem>
      {props.modalContent(<StatusChangeLog file={file} {...props} />, {
        showCloseIcon: true,
        modalStyles: { width: "1200px" },
        closeOnOverlayClick: false,
      })}
    </>
  ),
);

export default FileStatusChangeLogButton;
