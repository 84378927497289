/** @format */

import React from "react";
import { withOktaAuth } from "@okta/okta-react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import RequirementDueDate from "../types/RequirementDueDate";
import RequirementEditFormButton from "./RequirementEditForm";
import Requirement from "../types/Requirement";
import { describeFrequencyType, welltowerName } from "../util/NameUtils";
import RequirementDueDateEditFormButton from "./RequirementDueDateEditForm";
import { formatDate, formatDateISO, parseDate } from "../util/DateUtils";
import _ from "lodash";
import { deleteWithToken, fetchPageableAllWithToken } from "../util/FetchUtils";
import { DeveloperModeOnly, withDeveloperMode } from "./DeveloperMode";
import RequirementDueDateManualCreationFormButton from "./RequirementDueDateManualCreationFormButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

interface RequirementCardProps extends IOktaContext {
  requirement: Requirement;
  receiveUpdatedRequirement: (r: Requirement) => void;
  deleteRequirement: (r: Requirement) => void;
  canEdit: boolean;
  canEditDueDates: boolean;
  developerMode: boolean;
}

const dueDatesEndpoint = (requirement: Requirement) =>
  `/api/operators/${requirement.operator.id}/requirements/${requirement.id}/dueDates/open`;

export default withDeveloperMode(
  withOktaAuth(
    class RequirementCard extends React.Component<RequirementCardProps, any> {
      constructor(props: RequirementCardProps) {
        super(props);
        this.state = {};
        this.receiveUpdatedRequirementDueDate =
          this.receiveUpdatedRequirementDueDate.bind(this);
        this.deleteRequirementDueDate =
          this.deleteRequirementDueDate.bind(this);
      }

      async componentDidMount() {
        this.loadDueDates(this.props.requirement);
      }

      async componentDidUpdate(prevProps: any) {
        if (
          this.props.requirement.glDateSetting !==
          prevProps.requirement.glDateSetting
        ) {
          this.loadDueDates(this.props.requirement);
        }
      }

      async loadDueDates(requirement: Requirement) {
        fetchPageableAllWithToken(
          dueDatesEndpoint(requirement),
          this.props.authState,
          25,
        )
          .then((data: any) => {
            //if (!(options.signal && options.signal.aborted)) {
            //if (data.content) {
            // TODO - handle pageable content
            this.setState({ dueDates: data, error: null });
            //}
            //}
          })
          .catch((error) => {
            console.error(error);
            // TODO - what to do in this case? Just nothing?
            //if (componentThis && !(options.signal && options.signal.aborted))
            this.setState({ error: error });
          });
      }

      receiveUpdatedRequirementDueDate(rdd: RequirementDueDate) {
        if (!rdd) return;
        // If our save endpoint returns the full RequirementDueDate object in the shape we want, replace the one in state with the updated copy
        this.setState((prevState: any) => {
          let newDates = _.concat(prevState.dueDates);
          const existingIdx = _.findIndex(
            newDates,
            (rdd2: RequirementDueDate) =>
              rdd2.periodEndDate === rdd.periodEndDate,
          );
          if (existingIdx !== -1) {
            newDates[existingIdx] = rdd;
          } else {
            newDates.unshift(rdd);
          }
          return {
            dueDates: newDates,
          };
        });
      }
      async deleteRequirementDueDate(requirementDueDate: RequirementDueDate) {
        if (!requirementDueDate.id) return;

        const url = `/api/operators/${requirementDueDate.requirement.operator.id}/requirements/${requirementDueDate.requirement.id}/dueDates/${requirementDueDate.id}`;
        return deleteWithToken(url, this.props.authState).then((response) => {
          this.setState((prevState: any) => ({
            dueDates: prevState.dueDates.filter(
              (rdd2: RequirementDueDate) =>
                rdd2.periodEndDate !== requirementDueDate.periodEndDate,
            ),
          }));

          return response;
        });
      }

      render() {
        let endDateMessage = null;
        let endDateStatus = null;
        let showDueDates = true;
        let endDate = parseDate(this.props.requirement.endDate);
        if (endDate) {
          const millisAgo = Date.now() - endDate.getTime();
          const moreThanAMonthAgo = millisAgo > 1000 * 60 * 60 * 24 * 31; // roughly a month ago
          if (moreThanAMonthAgo) {
            endDateMessage = `Ended on: ${formatDate(endDate)}`;
            endDateStatus = "gainsboro";
            showDueDates = false;
          } else {
            endDateMessage = `Ending on: ${formatDate(endDate)}`;
            endDateStatus = "gainsboro";
          }
        }

        return (
          <Card style={{ backgroundColor: endDateStatus ?? "white" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col sm className={"requirementCard "}>
                    <DeveloperModeOnly>
                      <span className={"text-muted"}>
                        {this.props.requirement.operator.id}.
                        {this.props.requirement.id}
                      </span>{" "}
                    </DeveloperModeOnly>
                    {welltowerName(this.props.requirement.operator)}{" "}
                    {welltowerName(this.props.requirement)}
                  </Col>
                  <Col sm className={"requirementCard "}>
                    {describeFrequencyType(this.props.requirement)}
                    {this.props.requirement.glDateSetting &&
                      this.props.requirement.glDateSetting.name !==
                        "MONTH_END" && (
                        <div>
                          <i>
                            (Posting to Welltower on{" "}
                            {this.props.requirement.glDateSetting.displayName})
                          </i>
                        </div>
                      )}
                  </Col>
                  <Col sm={"1"} className={"requirementCard"}>
                    {this.props.requirement.nonCompliantFormat ? (
                      <>
                        <FontAwesomeIcon
                          icon={["fad", "wagon-covered"]}
                          size={"lg"}
                          title={"Non-compliant format"}
                        />
                        <FontAwesomeIcon
                          icon={["fad", "horse"]}
                          size={"lg"}
                          title={"Non-compliant format"}
                        />
                      </>
                    ) : this.props.requirement.rpaEnabled?.name ===
                      "AUTOMATIC" ? (
                      <FontAwesomeIcon
                        icon={["fal", "rocket-launch"]}
                        size={"lg"}
                        title={this.props.requirement.rpaEnabled.displayName}
                      />
                    ) : this.props.requirement.rpaEnabled?.name ===
                      "WITH_ACCOUNTANT_APPROVAL" ? (
                      <FontAwesomeIcon
                        icon={["fal", "user-robot"]}
                        size={"lg"}
                        title={this.props.requirement.rpaEnabled.displayName}
                      />
                    ) : null}
                  </Col>
                  <Col sm={"2"} className={"requirementCard text-right"}>
                    <RequirementEditFormButton
                      requirement={this.props.requirement}
                      receiveUpdatedRequirement={
                        this.props.receiveUpdatedRequirement
                      }
                      deleteRequirement={this.props.deleteRequirement}
                      canEdit={this.props.canEdit}
                    />
                  </Col>
                </Row>
                {this.props.requirement.crossValidationRequirement && (
                  <Row>
                    <span style={{ padding: "0.375rem 0.75rem" }}>
                      Validates against{" "}
                      {welltowerName(
                        this.props.requirement.crossValidationRequirement,
                      )}
                    </span>
                  </Row>
                )}
                {(this.props.developerMode ||
                  showDueDates ||
                  (this.state.dueDates && this.state.dueDates.length > 0)) && (
                  <Row>
                    <span style={{ padding: "0.375rem 0.75rem" }}>
                      Open due dates:{" "}
                    </span>
                    {this.state.dueDates &&
                      this.state.dueDates.map((rdd: RequirementDueDate) => (
                        <RequirementDueDateEditFormButton
                          key={`${rdd.id}:${formatDateISO(rdd.periodEndDate)}`}
                          requirementDueDate={rdd}
                          receiveUpdatedRequirementDueDate={
                            this.receiveUpdatedRequirementDueDate
                          }
                          deleteRequirementDueDate={
                            this.deleteRequirementDueDate
                          }
                          canEdit={this.props.canEditDueDates}
                        />
                      ))}
                    {this.props.canEditDueDates &&
                      (this.props.developerMode || !endDateMessage) && (
                        <RequirementDueDateManualCreationFormButton
                          requirement={this.props.requirement}
                          callback={() =>
                            this.loadDueDates(this.props.requirement)
                          }
                        />
                      )}
                  </Row>
                )}
                {endDateMessage && (
                  <Row>
                    <span style={{ padding: "0.375rem 0.75rem" }}>
                      {endDateMessage}
                    </span>
                  </Row>
                )}
              </Container>
              <aside />
            </CardBody>
          </Card>
        );
      }
    },
  ),
);
