/** @format */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import DocumentType from "../../types/DocumentType";
import {
  addDocumentTypeAsFavorite,
  deleteDocumentTypeAsFavorite2,
} from "../../api/SharedAPI";
import Select, { SelectInstance } from "react-select";
import useFetchJson from "../../hooks/useFetchJson";
import { useOktaAuth } from "@okta/okta-react";
import { DocumentTypeContext } from "./PickerContext";
import { selectTheme } from "../form/WrappedSelectInput";
import {
  DocumentTypeChoice,
  DocumentTypePickerId,
  HasID,
  IDocumentTypePickerProps,
  IFilterPickerProps,
  PickerId,
} from "../../types/PickerTypes";
import { PickerSelectOption } from "./generic/ContextPicker";
import { useSearchParamsStateSticky } from "../../hooks/useSearchParamsStateSticky";

// TODO Favorites should be working but haven't been tested. I don't think we ever actually used Favorite Operators, and maybe we don't need that with react-select?

const traceLogging = process.env.REACT_APP_PICKER_LOGGING || false;

function bestGuessInitialValue<T extends HasID<any>, PID extends PickerId<any>>(
  availableItems: T[],
  { selectedId, showAllChoice, requireChoice }: IFilterPickerProps<T, PID>,
) {
  const allowFavorites = true; // TODO - move this back into props?

  // Assume this function is only called with loaded and filtered availableItems

  const selectedDocumentTypeId: PickerId<any> = selectedId;
  let ourChoice = selectedDocumentTypeId;

  // Determine if the selected item is valid
  const selectedItemIsValid =
    selectedDocumentTypeId &&
    selectedDocumentTypeId !== "NOT_SELECTED" &&
    ((selectedDocumentTypeId === "FAVORITES" && allowFavorites) ||
      (selectedDocumentTypeId === "ALL" && showAllChoice) ||
      availableItems.find((t) => t.id === selectedDocumentTypeId));

  // Track our state machine
  //let choiceWasCleared = false;
  let choiceMadeCount = 0;

  if (!selectedItemIsValid) {
    console.log(
      `clearing ${selectedDocumentTypeId} because it isn't allowed or isn't in the list of ${availableItems.length} available items`,
    );
    ourChoice = undefined;
    //choiceWasCleared = true;
  }
  // TODO - should we treat an incoming undefined differently from an incoming NOT_SELECTED? Should incoming NOT_SELECTED be treated as an intentional choice and skip our guessing?

  // if no (valid) choice was made, pick the first one
  if (!ourChoice && requireChoice) {
    // This makes sticky the first documentType if one hasn't been chosen. Do we need to do that explicitly? Or can we let the Select require a choice and call us back (not sure if that causes the same setState-in-render issue?)
    traceLogging &&
      console.debug(
        `setting first documentType (of ${availableItems.length}) because previously unselected`,
      );
    const sortedAvailable = _.sortBy(availableItems, ["name"]);
    ourChoice = sortedAvailable[0].id;
    choiceMadeCount++;
    // expect to call onSelectionChange later
  }

  // else if we don't require a choice and allow All, choose All
  if (
    !ourChoice &&
    !requireChoice &&
    showAllChoice
    // Technically we don't have to wait for loadedDocumentTypes yet here, but that's probably simpler
  ) {
    traceLogging &&
      console.debug(
        "setting ALL because previously unselected and requireDocumentTypeChoice=false",
      );
    ourChoice = "ALL";
    choiceMadeCount++;
    // expect caller to call onSelectionChange later
  }
  if (choiceMadeCount > 1) {
    console.warn(
      `bestGuessInitialValue guessed ${choiceMadeCount} times, this is unexpected.`,
    );
  }
  return ourChoice;
}

const BaseDocumentTypePicker = (props: IDocumentTypePickerProps) => {
  const { authState } = useOktaAuth();
  const { data: allDocumentTypes, loading } =
    useFetchJson<DocumentType[]>("/api/documentTypes");
  let documentTypes = useMemo(() => {
    let filteredTypes = allDocumentTypes ?? [];
    if (props.visibilityFilter) {
      filteredTypes = filteredTypes?.filter(props.visibilityFilter);
    }
    return filteredTypes;
  }, [allDocumentTypes, props.visibilityFilter]);

  const {
    data: favorites,
    //loading: loadingFavorites,
    forceReload: reloadFavorites,
  } = useFetchJson<DocumentType[]>("/api/documentTypes/my2", {
    keepDataAcrossReload: true,
  });
  // TODO - PickerContext also loads favorites, consolidate these
  const selectedDocumentTypeId = props.selectedId;

  // I think react-query should always return exactly one of data or loading, but I'm not certain
  const loaded = !loading && documentTypes && documentTypes.length > 0;

  // If we haven't selected an DocumentType, pick a default choice instead of starting blank (TODO - maybe allow blanks for some Reports pickers that don't allow All?)
  useEffect(() => {
    if (!loaded) {
      traceLogging && console.debug("not loaded");
      return;
    }

    const bestGuess = bestGuessInitialValue(documentTypes, props);
    traceLogging && console.debug(`best guess ${bestGuess}`);

    // Callback if our best guess is a change
    // or if our best guess is an object ID that doesn't match selectedObj
    const callCallback =
      (bestGuess && bestGuess !== props.selectedId) ||
      (bestGuess &&
        !["ALL", "FAVORITES", "NOT_SELECTED"].includes(bestGuess) &&
        props.selectedObj?.id !== bestGuess);

    if (callCallback && props.onSelectionChange)
      props.onSelectionChange(
        bestGuess,
        documentTypes.find((dt) => dt.id === bestGuess),
      );
  }, [
    props,
    props.requireChoice,
    selectedDocumentTypeId,
    props.showAllChoice,
    loaded,
    documentTypes,
    props.onSelectionChange,
  ]);
  // TODO - can we trigger this when removing the last favorite from an allowAllAndFavorites picker that all

  const getFavorite = (documentType: DocumentType) => {
    return _.find(favorites, (fav: any) => {
      return fav.id === documentType.id;
    });
  };

  const onAddFavorite = async (documentTypeId: number) => {
    try {
      await addDocumentTypeAsFavorite(documentTypeId, authState);
      reloadFavorites().then((favs) => {
        // TODO - we may need to pass the user's favorites as a prop from the context to force children to re-render and re-filter on the new favorites?

        if (props.onSelectionChange) props.onSelectionChange("FAVORITES", favs);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteFavorite = async (documentTypeId: number) => {
    try {
      await deleteDocumentTypeAsFavorite2(documentTypeId, authState);
      reloadFavorites().then((favs) => {
        if (props.onSelectionChange) {
          if (favs.length > 0) {
            props.onSelectionChange("FAVORITES", favs);
          } else if (props.showAllChoice) {
            // Switch back to All DocumentTypes when removing last favorite
            props.onSelectionChange("ALL");
          } else {
            // If we're here, an documentType should already be selected, so no need to change anything?
            // TODO - I think this should trigger the useEffect that assigns all or first? (and if so, I think we can remove the "ALL" above here and let "NOT_SELECTED" do that logic too?)
            props.onSelectionChange("NOT_SELECTED");
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  if (loading || !documentTypes || documentTypes.length === 0) {
    traceLogging &&
      console.debug(
        `documentTypes loading=${loading} or ${documentTypes?.length}`,
      );
    return null;
  }
  const enableAllAndFavorites = !props.requireChoice;
  const enableAll = props.showAllChoice;
  const enableFavorites = !props.requireChoice;
  //if (enableAllAndFavorites)
  //  console.warn(
  //    "DocumentTypePickerSelect has requireDocumentTypeChoice=false",
  //  );
  //if (enableAll)
  //  console.warn("DocumentTypePickerSelect has showAllDocumentTypeChoice=true");
  const chosenDocumentType =
    selectedDocumentTypeId &&
    documentTypes.find((dt: DocumentType) => dt.id === selectedDocumentTypeId);

  if (
    chosenDocumentType &&
    props.selectedId &&
    props.selectedId !== chosenDocumentType.id
  ) {
    // We loaded from session, send a callback to parent
    // TODO - this logs a "cannot update state during render" from the parent component
    // TODO - not sure how we can trigger this, unless we move sticky documentType up to a higher context, which I think I started at one point?
    traceLogging &&
      console.info(
        `Got a session documentType ${chosenDocumentType.id}, but we were passed in a different documentType ${props.selectedId}. Trying to push the one from the session.`,
      );
    props.onSelectionChange &&
      props.onSelectionChange(selectedDocumentTypeId, chosenDocumentType);
  }

  //if (props.requireChoice && documentTypes && documentTypes.length === 1) {
  //  console.warn(
  //    "not returning documentType picker because there's only one documentType",
  //  ); // TODO - does this really ever happen?
  //  return null;
  //}
  // @ts-ignore
  let allDocumentTypeChoices: DocumentTypeChoice[] = [];

  if (enableAllAndFavorites || enableAll) {
    allDocumentTypeChoices.push({
      value: "ALL",
      label: "All Documents",
      obj: null,
    });
  }
  if (enableFavorites) {
    allDocumentTypeChoices.push({
      value: "FAVORITES",
      label: "Favorite Documents",
      obj: null,
    });
    allDocumentTypeChoices.push({
      value: "DIVIDER",
      label: "DIVIDER",
      obj: null,
      isDisabled: true,
    });
  }

  // @ts-ignore
  allDocumentTypeChoices = allDocumentTypeChoices.concat(
    // @ts-ignore
    // documentTypes are sorted in the backend, don't need to sort here
    _.sortBy(
      documentTypes,
      (dt) => !favorites?.find((f) => f.id === dt.id),
      "sortOrder",
      "name",
    ).map((dt: DocumentType) => ({
      value: dt.id,
      label: dt.name,
      obj: dt,
    })),
  );

  return (
    <Select
      data-testid={"documentType-picker-select"} // Somehow this isn't visible in tests?
      className={"documentType-picker-select"}
      theme={selectTheme}
      options={allDocumentTypeChoices}
      isMulti={false}
      isClearable={false}
      onChange={(v) => {
        if (props.onSelectionChange)
          props.onSelectionChange(v!.value as DocumentTypePickerId, v?.obj!);
      }}
      value={allDocumentTypeChoices.find(
        (a: DocumentTypeChoice) =>
          a.value === selectedDocumentTypeId /* || "NOT_SELECTED"*/,
      )}
      components={{ Option: PickerSelectOption }}
      // @ts-ignore
      enableAllAndFavorites={enableAllAndFavorites}
      enableAll={enableAll}
      enableFavorites={enableFavorites}
      getFavorite={getFavorite}
      onAddFavorite={onAddFavorite}
      onDeleteFavorite={onDeleteFavorite}
      ref={props.selectRef}
    />
  );
};

// This is just a picker with callbacks, for use outside of the PickerContext (as in "not being used with", not "not inside of in the React component tree", because the PickerContext wraps most routes)
// Use this one for multiples on a page like Reports. It expects requireDocumentTypeChoice, doesn't handle Favorites, and probably expects showAllDocumentTypeChoice
export const StandaloneDocumentTypePicker = (
  props: IDocumentTypePickerProps,
) => {
  const [selectedId, setDocumentTypeId] = useState<DocumentTypePickerId>(
    props.selectedId || "NOT_SELECTED",
  );
  //const [selectedDocumentTypeObj, setDocumentTypeObj] = useState<DocumentType>();

  const propsOnSelectionChange = props.onSelectionChange;
  const onSelectionChange = useCallback(
    (id: DocumentTypePickerId, op?: any) => {
      traceLogging &&
        console.debug(`DocumentTypePickerSelect: Select new: ${id}`);
      setDocumentTypeId(id);
      //setDocumentTypeObj(op);
      if (propsOnSelectionChange) propsOnSelectionChange(id, op); // TODO - remove this? and just call context setter
    },
    [setDocumentTypeId, propsOnSelectionChange],
  );

  return (
    <BaseDocumentTypePicker
      {...props}
      selectedId={selectedId}
      //selectedDocumentTypeObj={selectedDocumentTypeObj}
      onSelectionChange={onSelectionChange}
    />
  );
};

// TODO - maybe switch entirely to searchParams like PeriodPicker instead of Context?
// and/or can DocumentTypeContext get and set searchParams?
// We still need some layer to handle testing against favorites
// Maybe also take this chance to create explicit groupings of types, like "Wellness Housing" and lumping all of the "others" together?
// TODO - it might be simpler to rebuild this picker like PeriodPicker, but I've learned a lot.

// Use this one when DocumentType is a main option on the page, this one is sticky and context-based.
export const ContextDocumentTypePicker = (props: IDocumentTypePickerProps) => {
  let [paramsDocumentTypeId, setParamsDocumentTypeId] =
    useSearchParamsStateSticky(
      "documentType",
      "", // TODO?
    );

  const {
    selectedId: contextDocumentTypeId,
    selectedObj: contextObj,
    setSelection: setDocumentType,
  } = useContext(DocumentTypeContext);

  // slash shortcut to focus
  const selectRef = useRef<SelectInstance<DocumentTypeChoice> | null>(null);
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.code === "KeyD" && event.altKey) {
      const tagName = (event.target as any)?.tagName?.toLowerCase();
      if (tagName === "input" || tagName === "textarea") {
        traceLogging && console.debug(`ignoring keypress in ${tagName}`);
      } else {
        traceLogging &&
          console.debug(
            `SearchSlashListener saw alt-D from target  ${tagName}`,
          );
        selectRef.current?.focus();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // @ts-ignore
  const incomingSelectedId: DocumentTypePickerId | undefined =
    props.selectedId || paramsDocumentTypeId;
  const selectedId = incomingSelectedId || contextDocumentTypeId; // || props.selectedId; // TODO - can we remove the passed-in documentType?
  // This all seems a little complicated, but the interactions between loading documentTypes here and potentially updating an empty selectedId in the Context are tricky.
  const selectedObj = contextObj;
  const propsOnSelectionChange = props.onSelectionChange;
  const onSelectionChange = useCallback(
    (id: DocumentTypePickerId, op?: any) => {
      traceLogging &&
        console.debug(`DocumentTypePickerSelect: Select new: ${id}`);
      setDocumentType(id, op);
      setParamsDocumentTypeId(id);

      if (propsOnSelectionChange) propsOnSelectionChange(id, op); // TODO - remove this? and just call context setter
      // unfocus select here because that feels more like a navigation
      selectRef.current?.blur();
    },
    [setDocumentType, setParamsDocumentTypeId, propsOnSelectionChange],
  );

  useEffect(() => {
    if (incomingSelectedId && incomingSelectedId !== contextDocumentTypeId) {
      // If an documentType was passed in from the parent, and it doesn't match the context, update the context
      console.info(
        `Got a passed-in documentType ${incomingSelectedId}, but the sticky context had ${contextDocumentTypeId}. Trying to push the prop back up to context.`,
      );
      setDocumentType(incomingSelectedId);
      setParamsDocumentTypeId(incomingSelectedId);
    }
  }, [
    contextDocumentTypeId,
    incomingSelectedId,
    setDocumentType,
    setParamsDocumentTypeId,
  ]);

  return (
    <BaseDocumentTypePicker
      {...props}
      selectRef={selectRef}
      selectedId={selectedId}
      selectedObj={selectedObj}
      onSelectionChange={onSelectionChange}
    />
  );
};
