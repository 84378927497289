/** @format */
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useOktaAuth } from "@okta/okta-react";
import { authToTokenString, fetchJsonWithToken } from "../util/FetchUtils";
import { AbstractAuthState } from "../util/OktaUtils";

/* This is nearly a drop-in replacement for Tim's older useFetchJson and useFetchWithLoadingHook.
 * Will need to switch from `loading` to `isLoading`
 * Will need to make some changes to:
 *   keepDataAcrossReload - change to {keepDataAcrossReload: true}
 *   setData - not sure the best way to migrate this behavior
 *   forceReload - call `refresh` instead
 */

type UseOktaQueryOptions = {
  authState?: AbstractAuthState;
};

const useOktaQuery = <
  TQueryFnData = any, // ReactQuery uses =unknown , which then gets inferred from queryFn, but even though queryFn below returns Promise<any>, that doesn't flow back out to this hook's result types?
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  // Copy these types from useQuery
>(
  url: string,
  options: UseOktaQueryOptions &
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> = {},
) => {
  const { authState: oktaAuthState } = useOktaAuth();
  const { authState: fakeAuthState, ...rqOptions } = options;
  const authState = fakeAuthState ?? oktaAuthState;

  // If we have no token, no URL, or a URL with missing data, treat as enabled:false
  const badUrl = url?.match(/\bnull\b/) || url?.match(/\bundefined\b/);
  const looksGood = authState?.accessToken && url && !badUrl;
  const enabledOptions = looksGood ? {} : { enabled: false };

  // let react-query do all the work
  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey: [url, authToTokenString(authState)],
    queryFn: () => fetchJsonWithToken(url, authState),
    staleTime: 30 * 1000, // 30s, not sure if this is great, but probably a good start
    // I tried to define a type for the queryKey and the queryFn argument so I could just pass in `fetchJson` as a function,
    // but couldn't get it working, and we don't really care here anyway
    // TODO - react-query passes in an AbortController; pass this through to fetch/wretch implementation
    ...rqOptions,
    ...enabledOptions,
    // TypeScript (4) doesn't think this is a valid overload, so tell it we think it is
  } as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>);
};

export { useOktaQuery };
