/** @format */
import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { canManageMasterData, isWelltower } from "../../util/OktaUtils";
import { Button, Card, CardBody, Container, Table } from "reactstrap";
import dayjs from "dayjs";
import { LoadingSpinner } from "../../components/LoadingSpinners";
import { useOktaQuery } from "../../hooks/useOktaQuery";
import { useOktaMutation } from "../../hooks/useOktaMutation";
import _ from "lodash";

const MasterDataPage = () => {
  const { authState } = useOktaAuth();

  const {
    data,
    isLoading: dataLoading,
    //isError,
    refetch,
  } = useOktaQuery("/api/masterData/all");

  const mutation = useOktaMutation("/api/masterData/YARDI");

  if (!authState?.isAuthenticated) return null;
  if (!isWelltower(authState))
    return (
      <Container>
        <Card>
          <CardBody>Access denied.</CardBody>
        </Card>
      </Container>
    );

  const canManage = canManageMasterData(authState);

  return (
    <Container>
      <h3>Master Data Status</h3>
      <Table>
        <thead>
          <tr>
            <th />
            <th className={"text-sm-right"}>Records</th>
            <th>Source</th>
            <th>Last Loaded</th>
            {canManage && <th>Refresh</th>}
          </tr>
        </thead>
        <tbody>
          {_.orderBy(data, ["source.name", "name"], ["desc", "asc"])?.map(
            (d: any) => (
              <tr key={d.sourceName}>
                <td>{d.sourceName}</td>
                <td className={"text-right"}>{d.count}</td>
                <td>{d.lastLoad?.sourceInstance || ""}</td>
                <td>
                  {dataLoading || mutation.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    d.lastLoad && dayjs(d.lastLoad.loadTime).fromNow()
                  )}
                </td>
                {canManage && (
                  <td>
                    <Button
                      size={"sm"}
                      color={"primary"}
                      onClick={() =>
                        mutation.mutateAsync().then(() => refetch())
                      }
                      disabled={mutation.isLoading}
                    >
                      Refresh
                    </Button>
                  </td>
                )}
              </tr>
            ),
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default MasterDataPage;
