/** @format */
import DocumentType, { DocumentTypeId } from "../types/DocumentType";

export const ALL_TB_TYPES: DocumentTypeId[] = [
  "TRIAL_BALANCE",
  "TRIAL_BALANCE_YS",
  "TRIAL_BALANCE_NNN",
  "TRIAL_BALANCE_HLBV",
];

export const isTB = (documentType: DocumentType) => {
  return ALL_TB_TYPES.includes(documentType.id);
};

export const ALL_MC_TYPES: DocumentTypeId[] = [
  "MONTHLY_CENSUS",
  "MONTHLY_CENSUS_YS",
  "MONTHLY_CENSUS_NNN",
  "MONTHLY_CENSUS_HLBV",
];

export const isMC = (documentType: DocumentType) => {
  return ALL_MC_TYPES.includes(documentType.id);
};
