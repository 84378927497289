/** @format */
import UploadMethod from "../types/UploadMethod";
import {
  Country,
  GlDateSetting,
  Portfolio,
  ReportingPriority,
} from "../types/EnumWithDisplayName";

export const DAYS_TYPE_CALENDAR = {
  displayName: "Calendar days",
  name: "CALENDAR_DAYS",
};

export const FREQ_MONTHLY = {
  displayName: "Monthly",
  name: "MONTHLY",
};

export const RPA_ENABLED_OFF = {
  displayName: "Off",
  name: "OFF",
};

export const GL_DATE_MONTH_END: GlDateSetting = {
  displayName: "Month End",
  name: "Month End",
};

export const UPLOAD_METHOD_PORTAL: UploadMethod = {
  name: "PORTAL",
  displayName: "Portal",
  twoStageUpload: false,
  downloadable: true,
  deleteable: true,
  reuploadable: true,
};

export const UPLOAD_METHOD_CLOUDTICITY: UploadMethod = {
  name: "CLOUDTICITY",
  displayName: "Cloudticity",
  twoStageUpload: true,
  downloadable: false,
  deleteable: false,
  reuploadable: false,
};

export const UPLOAD_METHOD_S3: UploadMethod = {
  name: "S3",
  displayName: "Portal (direct to S3)",
  twoStageUpload: true,
  downloadable: true,
  deleteable: true,
  reuploadable: true,
};

export const PORTFOLIO_SHO: Portfolio = {
  displayName: "SHO",
  name: "SHO",
};
export const REPORTING_PRIORITY_MATERIAL: ReportingPriority = {
  displayName: "Material",
  name: "Material",
};

export const USA_USA: Country = { displayName: "USA", name: "USA" };
